import { useState } from "react";
import { Octokit } from "octokit";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Button from "@mui/material/Button";
import Title from "./Title";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SaveIcon from "@mui/icons-material/Save";

function Write() {
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSave = async () => {
    try {
      const ghlogin = localStorage.getItem("ghlogin");
      const token = localStorage.getItem("token");
      const octokit = new Octokit({ auth: token });

      const res = await octokit.request(
        "GET /repos/{ghlogin}/thoughtsdata/contents/thoughts.js",
        { ghlogin: ghlogin }
      );

      const sha = res.data.sha;
      const mess = decodeURIComponent(base64_decode(res.data.content));
      let messages = JSON.parse(mess);

      const newMessage = { date: new Date(), content: message };
      messages.push(newMessage);
      const str = JSON.stringify(messages);
      const base64 = base64_encode(encodeURIComponent(str));

      await octokit.request(
        "PUT /repos/{ghlogin}/thoughtsdata/contents/thoughts.js",
        {
          ghlogin: ghlogin,
          message: "commit",
          committer: {
            name: "Thoughts App",
            email: "thoughts@donotreply.com",
          },
          sha: sha,
          content: base64,
        }
      );

      setMessage("");
      setAlertMessage("Saved");
      setAlertType("success");
      setAlertOpen(true);
    } catch (e) {
      setAlertMessage(e.message);
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  return (
    <div>
      <Title />
      <br></br>
      <Container maxWidth="lg">
        <TextField
          id="outlined-multiline-static"
          label="Write something"
          multiline
          rows={8}
          autoFocus
          sx={{ width: "100%", maxWidth: "xl", minWidth: "sm" }}
          value={message}
          onChange={handleChange}
        />
        <br></br>
        <Button
          variant="outlined"
          size="large"
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          Save
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alertType}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}
export default Write;
