import React from "react";
import ReactDOM from "react-dom/client";
import Write from "./Write";
import Read from "./Read";
import Login from "./Login";

import { HashRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Write />} />
          <Route path="/read" element={<Read />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </HashRouter>
    </React.StrictMode>
  </ThemeProvider>
);
