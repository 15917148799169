import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Octokit } from "octokit";
import { decode as base64_decode } from "base-64";
import { Container, Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Title from "./Title";
import Thought from "./Thought";

function Read() {
  const [messages, setMessages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const ghlogin = localStorage.getItem("ghlogin");
        const token = localStorage.getItem("token");

        const octokit = new Octokit({ auth: token });
        const res = await octokit.request(
          "GET /repos/{ghlogin}/thoughtsdata/contents/thoughts.js",
          { ghlogin: ghlogin }
        );
        const mess = decodeURIComponent(base64_decode(res.data.content));
        let list = JSON.parse(mess);
        list.sort((a, b) => {
          const date1 = new Date(a.date);
          const date2 = new Date(b.date);
          return date2 - date1;
        });
        setMessages(list);
      } catch (error) {
        setErrorMessage(error.message);
        setOpenErrorMessage(true);
        navigate("/login");
      }
    }

    fetchData();
  }, [navigate]);

  const handleCloseErrorMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorMessage(false);
  };

  return (
    <div>
      <Title />
      <br />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {messages.map((n, i) => (
            <Thought key={n.date} content={n.content} date={n.date} />
          ))}
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openErrorMessage}
          autoHideDuration={6000}
          onClose={handleCloseErrorMessage}
        >
          <Alert
            onClose={handleCloseErrorMessage}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}
export default Read;
