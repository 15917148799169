import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { encode as base64_encode } from "base-64";
import Title from "./Title";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import { Octokit } from "octokit";

function Login() {
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorMessage, setOpenErrorMessage] = useState(false);

  let navigate = useNavigate();

  const handleLogin = async () => {
    try {
      localStorage.setItem("token", token);
      // get github login
      const octokit = new Octokit({ auth: token });
      const res = await octokit.request("GET /user", {});

      if (res.status === 200 && res.data.login) {
        const ghlogin = res.data.login;
        localStorage.setItem("ghlogin", ghlogin);

        // check if repo exists. If it doesn't create repo and iniitialize to []
        await octokit.request(
          "GET /repos/{ghlogin}/thoughtsdata/contents/thoughts.js",
          { ghlogin: ghlogin }
        );

        navigate("/");
      } else {
        setErrorMessage("Unable to login");
        setOpenErrorMessage(true);
      }
    } catch (e) {
      if (e.status === 404) {
        try {
          const ghlogin = localStorage.getItem("ghlogin");
          const octokit = new Octokit({ auth: token });

          await octokit.request("POST /user/repos", { name: "thoughts" });

          const messages = [];
          const str = JSON.stringify(messages);
          const base64 = base64_encode(encodeURIComponent(str));

          await octokit.request(
            "PUT /repos/{ghlogin}/thoughtsdata/contents/thoughts.js",
            {
              ghlogin: ghlogin,
              message: "commit",
              committer: {
                name: "Thoughts App",
                email: "thoughts@donotreply.com",
              },
              content: base64,
            }
          );

          navigate("/");
        } catch (e) {
          setErrorMessage(e.message);
          setOpenErrorMessage(true);
        }
      } else {
        setErrorMessage(e.message);
        setOpenErrorMessage(true);
      }
    }
  };

  useEffect(() => {
    setToken(localStorage.getItem("token") ?? "");
  }, []);

  const handleCloseErrorMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorMessage(false);
  };

  return (
    <div>
      <Title />
      <br />
      <Container maxWidth="lg">
        <TextField
          id="token"
          label="GitHub Token, e.g. ghp_xxxx"
          type="password"
          variant="filled"
          sx={{ width: "100%", padding: "2px" }}
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />

        <Button
          variant="outlined"
          size="large"
          startIcon={<LoginIcon />}
          onClick={handleLogin}
        >
          Login
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openErrorMessage}
          autoHideDuration={6000}
          onClose={handleCloseErrorMessage}
        >
          <Alert
            onClose={handleCloseErrorMessage}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}

export default Login;
