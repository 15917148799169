import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

const linkStyle = {
  fontSize: 20,
  paddingRight: 2,
  paddingLeft: 2,
};

const Title = () => {
  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src="/logo.png"
            alt="logo"
            style={{ width: 40, height: 40 }}
          ></img>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Thoughts...
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
            <Link href="/#/login" underline="hover" sx={linkStyle}>
              Login
            </Link>
            <Link href="#" underline="hover" sx={linkStyle}>
              Write
            </Link>
            <Link href="/#/read" underline="hover" sx={linkStyle}>
              Read
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Title;
