import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' };

function Thought(props) {

  return (
    <Card key={props.date} sx={{ minWidth: 300, maxWidth: 500, margin: 1 }}>
      <CardContent>
        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap"}}>
          {props.content}
        </Typography>
        <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
          {new Date(props.date).toLocaleDateString("en-CA", options)}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Thought;
